import { initializeApp } from "firebase/app";
import { getAuth, RecaptchaVerifier, GoogleAuthProvider, signInWithPopup } from "firebase/auth";

const firebaseConfig = {
    apiKey: "AIzaSyDmp_0I6xVWEXr-ssVirR9YjNBQpPCcmnc",
    authDomain: "ludokingfisher-39856.firebaseapp.com",
    projectId: "ludokingfisher-39856",
    storageBucket: "ludokingfisher-39856.appspot.com",
    messagingSenderId: "1089735435794",
    appId: "1:1089735435794:web:c5aa5fecd6c40a93de1acb",
    measurementId: "G-S946XT3PD8"
};

const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);